<template>
  <div>2</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
  
    }
};
</script>

<style  scoped>

</style>


